@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "GentiumBook";
  font-style: normal;
  font-weight: normal;
  src: url("./fonts/gentium-book-basic.regular.ttf") format("truetype");
}
@font-face {
  font-family: "GentiumBook";
  font-style: normal;
  font-weight: bold;
  src: url("./fonts/gentium-book-basic.bold.ttf") format("truetype");
}
html {
  scroll-behavior: smooth;
  -webkit-text-size-adjust: none;
  -webkit-appearance: none;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p,
a,
dd,
span,
strong {
  font-family: "GentiumBook";
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6,
dt {
  font-family: "GentiumBook";
  font-style: bold;
}
